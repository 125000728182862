import React from "react"
import { ScrollMagicProvider } from "../helpers/scrollMagicContext"
import ContactContent from "../components/contactPage/contactContent"
import SEO from "../components/utils/seo"

const ContactPage = props => {
  return (
    <ScrollMagicProvider>
      <SEO title="Contact" />
      <ContactContent />
    </ScrollMagicProvider>
  )
}

export default ContactPage
