import React from "react"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
import gsap, { TweenMax, TimelineMax } from "gsap"

const defaultState = {
  scrollMagic: null,
}

class ScrollMagicProvider extends React.Component {
  render() {
    const { children } = this.props
    if (typeof window !== "undefined") {
      const ScrollMagic = require("scrollmagic")
      ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)
      gsap.registerPlugin()
      return (
        <ScrollMagicContext.Provider
          value={{
            scrollMagic: ScrollMagic,
          }}
        >
          {children}
        </ScrollMagicContext.Provider>
      )
    } else {
      return null
    }
  }
}

const ScrollMagicContext = React.createContext(defaultState)

export default ScrollMagicContext
export { ScrollMagicProvider }
