import React, { useRef, useEffect, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TweenMax, Power3, Expo, TimelineMax } from "gsap"

import BaseLayout from "../layout/base"
import {
  Box,
  ImageWrapper,
  SectionParagraph,
  SectionTitle,
  ButtonRow,
  FullScreenContainer,
  TextRow,
  Text,
} from "../../helpers/styledComponents"
import ScrollMagicContext from "../../helpers/scrollMagicContext"
import styled from "@emotion/styled-base"
import theme from "../../helpers/theme"
import useMedia from "../../helpers/useMedia"

const Contact = props => {
  const scrollMagicConsumer = useContext(ScrollMagicContext)
  const { scrollMagic } = scrollMagicConsumer

  let container = useRef(null)
  let topImage = useRef(null)
  let bottomImage = useRef(null)
  let content = useRef(null)
  let controller = new scrollMagic.Controller()
  const topImageTimeline = new TimelineMax()

  const textOffset = useMedia(
    [
      `(min-width: ${theme.breakpoints[0]})`,
      `(min-width: ${theme.breakpoints[1]})`,
    ],
    ["-250", "-200"],
    "-300"
  )

  const bottomImageOffset = useMedia(
    [
      `(min-width: ${theme.breakpoints[0]})`,
      `(min-width: ${theme.breakpoints[1]})`,
    ],
    ["100", "160"],
    "-500"
  )

  useEffect(() => {
    const sectionTitle = content.firstElementChild.firstElementChild
    const sectionParagraph = content.children[1]
    const actionLink = content.children[2]
    const scrollLine = topImage.lastElementChild.firstElementChild
    const scrollText = topImage.lastElementChild.lastElementChild

    // remove initial flash
    TweenMax.to(container, 0, {
      css: { visibility: "visible", background: theme.colors.white },
    })

    // top image animation
    topImageTimeline
      .from(topImage.firstElementChild, 1.5, {
        scale: 1.3,
        ease: Expo.easeOut,
      })
      .staggerFrom(
        [scrollLine, scrollText],
        1.2,
        { opacity: 0, y: 45, ease: Expo.easeOut },
        0.15
      )

    // text animation
    const textAnimationTimeline = new TimelineMax()
      .from(sectionTitle.children, 3, {
        y: 44,
        ease: Power3.easeOut,
      })
      .from(sectionParagraph, 1, { y: 20, opacity: 0, ease: Power3.easeOut })
      .from(actionLink, 1, { y: 20, opacity: 0, ease: Power3.easeOut })
    new scrollMagic.Scene({
      triggerElement: "#contentTrigger",
      duration: 400,
      offset: textOffset,
    })
      .setTween(textAnimationTimeline)
      .addTo(controller)

    // bottom image animation
    const bottomImageTimeline = new TimelineMax().from(
      bottomImage.firstElementChild,
      2,
      { scale: 1.3, ease: Expo.easeOut }
    )
    new scrollMagic.Scene({
      triggerElement: "#bottomImageTrigger",
      duration: 400,
      offset: bottomImageOffset,
    })
      .setTween(bottomImageTimeline)
      .addTo(controller)
  })

  const data = useStaticQuery(graphql`
    query {
      wavingBabySeal: sanityPhoto(
        uniqueIdentifier: { eq: "waving-baby-seal" }
      ) {
        file {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        caption
      }
      restingFox: sanityPhoto(uniqueIdentifier: { eq: "resting-fox" }) {
        file {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        caption
      }
    }
  `)
  const email = "hello@jeremy-hsu.com"

  return (
    <BaseLayout>
      <Background
        ref={el => {
          container = el
        }}
      >
        <FullScreenContainer>
          <div id="topImageTrigger" />
          <Box
            id="topImage"
            ref={el => {
              topImage = el
            }}
            css={{
              overflow: "hidden",
            }}
          >
            <ImageWrapper
              imageUrl={data.restingFox.file.asset.fluid}
              showGap={true}
              styles={{
                maxHeight: "80vh",
                overflow: "hidden",
                scale: 1.3,
              }}
            />
            <ScrollIndicator>
              <Box>
                <VerticalLine />
              </Box>
              <ScrollIndicatorText>
                <ButtonRow>
                  <Text>Scroll</Text>
                </ButtonRow>
              </ScrollIndicatorText>
            </ScrollIndicator>
          </Box>
          <div id="contentTrigger" />
          <Content>
            <TextContainer
              id="content"
              ref={el => {
                content = el
              }}
            >
              <TextRow>
                <SectionTitleOuter>
                  <SectionTitle>Get In Touch</SectionTitle>
                </SectionTitleOuter>
              </TextRow>
              <SectionParagraphOuter>
                <SectionParagraph>
                  For any enquiries, please feel free to reach out to the email
                  below. We would love to hear from you!
                </SectionParagraph>
              </SectionParagraphOuter>
              <TextRow>
                <ButtonRow>
                  <Text>{email.toUpperCase()}</Text>
                </ButtonRow>
              </TextRow>
            </TextContainer>
          </Content>
          <div id="bottomImageTrigger" />
          <Box
            id="bottomImage"
            ref={el => {
              bottomImage = el
            }}
            css={{
              overflow: "hidden",
            }}
            pb={[4]}
          >
            <ImageWrapper
              imageUrl={data.wavingBabySeal.file.asset.fluid}
              showGap={true}
              styles={{
                overflow: "hidden",
              }}
            />
          </Box>
        </FullScreenContainer>
      </Background>
    </BaseLayout>
  )
}

const Background = styled(Box)({
  visibility: "hidden",
  background: theme.colors.white,
  padding: `${theme.spaces[3]}px 0`,
  [theme.mediaQueries[3]]: {
    padding: `${theme.spaces[1]}px 0`,
  },
})

const Content = styled(Box)({
  display: "flex",
  justifyContent: "center",
  minHeight: "360px",
  [theme.mediaQueries[2]]: {
    minHeight: "30vh",
  },
  [theme.mediaQueries[3]]: {
    minHeight: "70vh",
  },
})

const TextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
})

const SectionTitleOuter = styled(Box)({
  margin: 0,
  height: "40px",
  overflow: "hidden",
})

const SectionParagraphOuter = styled(Box)({
  paddingTop: `${theme.spaces[4]}px`,
  maxWidth: "420px",
  display: "flex",
  justifyContent: "center",
})

const VerticalLine = styled(Box)({
  display: "block",
  position: "relative",
  top: "-50px",
  background: theme.colors.black,
  height: "50px",
  width: "1px",
})

const ScrollIndicator = styled(Box)({
  display: "none",
  flexDirection: "column",
  alignItems: "center",
  [theme.mediaQueries[3]]: {
    display: "flex",
  },
})

const ScrollIndicatorText = styled(Box)({
  transform: "translate(0px, -35px)",
})

export default Contact
